import React from 'react';

const SoumettreVideo = () => {
  return (
    <div>
      Vous pouvez nous envoyer vos vidéos à : teamorga.fea@gmail.com . Après validation par <br/>
      l'équipe , votre création apparaitra ic pour tous les membres. Des logiciels  de montage user-friendly, <br/>
      pourront vous etre recommandés sur simple demande .<br/> Devenez votre propre journaliste et votre créateur
      de contenu pour la communauté  FEA :-)
    </div>
  );
};

export default SoumettreVideo;
