import React from 'react'
import AtefImage from '../../../image/icon19.png'; // Importez l'image en utilisant require

const Icon19 = () => {
  return (
       <div>
       <img src={AtefImage}  alt="" />
       </div>
  )
}

export default Icon19