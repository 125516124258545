import React from 'react';

const Contact = () => {
  return (
    <div style={{ marginBottom: '800px' }}>
      <h1>A venir</h1> 
      
    </div>
  );
};

export default Contact;