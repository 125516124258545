import React, { useState, useEffect } from 'react';
import NavbarLateralEntraide from './NavbarLateralEntraide';
import EntraideCard from './EntraideCard';
import PlanComponent from './planComponent';
import { useSelector } from 'react-redux';
import { ShowEntraidesApi } from "../../redux/actions/entraide/entraideActions";
import { connect } from 'react-redux';
import Contact from './Contact';
import Actions from './Actions';
import Rejoignez from './Rejoignez';
import Stories from './Stories';
import FeedBack from './FeedBack';
import Recommandation from './Recommandation';
import MenuIcon from '@mui/icons-material/Menu';

const AppNavbar = ({ ShowEntraidesApi }) => {
  const auth = useSelector(state => state.auth);
  const token = useSelector(state => state.token);
  const entraides = useSelector(state => state.entraides);

  const [selectedContent, setSelectedContent] = useState(null);
  const [showFooter, setShowFooter] = useState(false); 
  const [showHeader, setShowHeader] = useState(false); 
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isNavbarOpen, setIsNavbarOpen] = useState(true); // Navbar ouverte par défaut

  const activeEntraides = entraides.filter(entraide => entraide.isActive);
  const firstActiveEntraide = activeEntraides[0] || {};

  useEffect(() => {
    ShowEntraidesApi(token);

    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [token]);

  const contentData = {
    'Équipe': (
      <EntraideCard
        imageUrl={firstActiveEntraide.imageUrl}
        description={firstActiveEntraide.selectedClients?.[0]?.firstName}
        fonction={firstActiveEntraide.fonction}
      />
    ),
    'Rejoignez-nous': <Rejoignez />, 
    'Notre Plan ': <PlanComponent />, 
    'Nos success stories': <Stories />, 
    'FEA Feed Back': <FeedBack />, 
    'FEA Recommandations': <Recommandation />, 
    'Contact': <Contact />,
  };

  const handleMenuClick = (itemName) => {
    setSelectedContent(contentData[itemName] || null);

    if (itemName === 'Équipe') {
      setShowFooter(true);
      setShowHeader(true); 
    } else {
      setShowFooter(false);
      setShowHeader(false); 
    }
    setIsNavbarOpen(false); // Fermer la navbar après sélection
  };

  return (
    <div style={styles.container}>
      {showHeader && firstActiveEntraide.title && firstActiveEntraide.selectedClients?.[0]?.firstName && (
        <div style={styles.header}>
          {firstActiveEntraide.title} {firstActiveEntraide.selectedClients[0].firstName}
        </div>
      )}

      <div style={styles.main}>
        <div onClick={() => setIsNavbarOpen(!isNavbarOpen)} style={styles.menuIcon}>
          <MenuIcon />
        </div>

        {isNavbarOpen && <NavbarLateralEntraide onMenuItemClick={handleMenuClick} />}

        <div style={styles.content}>
          {selectedContent}
        </div>
      </div>

      {!isMobile && showFooter && firstActiveEntraide.title && firstActiveEntraide.selectedClients?.[0]?.firstName && (
        <div style={styles.footer}>
          {firstActiveEntraide.title} {firstActiveEntraide.selectedClients[0].firstName}
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  header: {
    textAlign: 'center',
    padding: '1px',
    backgroundColor: '#f1f1f1',
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'row',
    marginTop: '20px',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginTop: '20px',
    padding: '10px',
  },
  footer: {
    position: 'fixed',
    bottom: '60px',
    width: '100%',
    textAlign: 'center',
    padding: '10px',
    backgroundColor: '#f1f1f1',
    zIndex: 1000,
  },
  menuIcon: {
    cursor: 'pointer',
    padding: '10px',
    backgroundColor: '#eee',
    borderRadius: '5px',
  },
  '@media (max-width: 768px)': {
    main: {
      flexDirection: 'column',
    },
    content: {
      marginTop: '10px',
      padding: '5px',
    },
    footer: {
      display: 'none',
    },
  },
};

export default connect(null, { ShowEntraidesApi })(AppNavbar);

