import React from 'react';

const Actions = ({description}) => {
  return (
    <div style={{ marginBottom: '800px' }}>
      <h1>A venir </h1> 
      
    </div>
  );
};

export default Actions;