import React from 'react'

const Podcast = () => {
  return (
    <div>
        <h1>Retrouver ici les Podcasts de Mohamed Nassiri tout
            connaitre de FEA
        </h1>
    </div>
  )
}

export default Podcast