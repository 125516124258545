import React from 'react'
import AtefImage from '../../../image/icon3.png'; // Importez l'image en utilisant require

const icon3 = () => {
  return (
    <div>
            <img src={AtefImage}  alt="" />

    </div>
  )
}

export default icon3