import React from 'react';

const Conciergerie = ({description}) => {
  return (
    <div style={{ marginBottom: '800px' }}>
      <h1>
        Gros sujet conciergerie ! Sur le long terme pourquoi <br/>
        pas négocier pour nos membres des prestations de<br/>
        voyage de qualité ? Celq q été identifié comme une piste <br/>
        de monétisation de FEA lors du dernier team meeting<br/>
        en avril 2020 ... donc nous y travaillons .
        </h1> <br/>
      
    </div>
  );
};

export default Conciergerie;