export const EDUCATION_CREATE_REQUEST = 'EDUCATION_CREATE_REQUEST'
export const EDUCATION_CREATE_SUCCESS = 'EDUCATION_CREATE_SUCCESS'
export const EDUCATION_CREATE_FAIL = 'EDUCATION_CREATE_FAIL'
export const EDUCATION_CREATE_RESET = 'EDUCATION_CREATE_RESET'


export const EDUCATION_DELETE_REQUEST = 'EDUCATION_DELETE_REQUEST'
export const EDUCATION_DELETE_SUCCESS = 'EDUCATION_DELETE_SUCCESS'
export const EDUCATION_DELETE_FAIL = 'EDUCATION_DELETE_FAIL'



export const EDUCATION_LIST_REQUEST = 'EDUCATION_LIST_REQUEST'
export const EDUCATION_LIST_SUCCESS = 'EDUCATION_LIST_SUCCESS'
export const EDUCATION_LIST_FAIL = 'EDUCATION_LIST_FAIL'



export const EDUCATION_DETAILS_REQUEST = 'EDUCATION_DETAILS_REQUEST'
export const EDUCATION_DETAILS_SUCCESS = 'EDUCATION_DETAILS_SUCCESS'
export const EDUCATION_DETAILS_FAIL = 'EDUCATION_DETAILS_FAIL'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'


export const EDUCATION_UPDATE_REQUEST = 'EDUCATION_UPDATE_REQUEST'
export const EDUCATION_UPDATE_SUCCESS = 'EDUCATION_UPDATE_SUCCESS'
export const EDUCATION_UPDATE_FAIL = 'EDUCATION_UPDATE_FAIL'
export const EDUCATION_UPDATE_RESET = 'EDUCATION_UPDATE_RESET'


export const ENVOYER_MAIL_SUCCESS = 'ENVOYER_MAIL_SUCCESS'
export const ENVOYER_MAIL_FAILURE = 'ENVOYER_MAIL_FAILURE'
export const ENVOYER_MAIL_REQUEST = 'ENVOYER_MAIL_REQUEST'

export const ENVOYER_MAIL2_SUCCESS = 'ENVOYER_MAIL2_SUCCESS'
export const ENVOYER_MAIL2_FAILURE = 'ENVOYER_MAIL2_FAILURE'
export const ENVOYER_MAIL2_REQUEST = 'ENVOYER_MAIL2_REQUEST'

export const ENVOYER_MAIL3_SUCCESS = 'ENVOYER_MAIL3_SUCCESS'
export const ENVOYER_MAIL3_FAILURE = 'ENVOYER_MAIL3_FAILURE'
export const ENVOYER_MAIL3_REQUEST = 'ENVOYER_MAIL3_REQUEST'


export const ENVOYER_MAIL4_SUCCESS = 'ENVOYER_MAIL4_SUCCESS'
export const ENVOYER_MAIL4_FAILURE = 'ENVOYER_MAIL4_FAILURE'
export const ENVOYER_MAIL4_REQUEST = 'ENVOYER_MAIL4_REQUEST'


export const ENVOYER_MAIL5_SUCCESS = 'ENVOYER_MAIL5_SUCCESS'
export const ENVOYER_MAIL5_FAILURE = 'ENVOYER_MAIL5_FAILURE'
export const ENVOYER_MAIL5_REQUEST = 'ENVOYER_MAIL5_REQUEST'

export const EDUCATION_UPDATE_STATUS_REQUEST = 'EDUCATION_UPDATE_STATUS_REQUEST';
export const EDUCATION_UPDATE_STATUS_SUCCESS = 'EDUCATION_UPDATE_STATUS_SUCCESS';
export const EDUCATION_UPDATE_STATUS_FAIL = 'EDUCATION_UPDATE_STATUS_FAIL';

// actionTypes.js
export const UPDATE_EDUCATION_STATUS_REQUEST = "UPDATE_EDUCATION_STATUS_REQUEST";
export const UPDATE_EDUCATION_STATUS_SUCCESS = "UPDATE_EDUCATION_STATUS_SUCCESS";
export const UPDATE_EDUCATION_STATUS_FAILURE = "UPDATE_EDUCATION_STATUS_FAILURE";