import React from 'react'
import AtefImage from '../../../image/icon21.png'; // Importez l'image en utilisant require

const Icon21 = () => {
  return (
    <div>
    <img src={AtefImage}  alt="" />
    </div>
  )
}

export default Icon21