import React from 'react'
import AtefImage from '../../image/News.png'; // Importez l'image en utilisant require

const NewsIcon = () => {
  return (
    <div>
    <img src={AtefImage}  alt="" />

    </div>
  )
}

export default NewsIcon