import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
  width: 500px;
  height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  text-align: center;
  font-family: Arial, sans-serif;
  position: relative;

  @media (max-width: 768px) {
    width: 90%;
    height: auto;
    padding: 20px;
    margin: 10px;
    box-shadow: none;
  }
`;

const ImageContainer = styled.div`
  width: 200px;
  height: 200px;
  position: relative;
  margin-bottom: 25px;
  margin-top: 30px;

  @media (max-width: 768px) {
    width: 150px;
    height: 150px;
  }
`;

const DiamondBackground = styled.div`
  position: absolute;
  width: 230px;
  height: 230px;
  background-color: #d3d3d3;
  transform: rotate(45deg);
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  z-index: 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Image = styled.img`
  width: 200px;
  height: 200px;
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  object-fit: contain;

  @media (max-width: 768px) {
    width: 150px;
    height: 150px;
  }
`;

const Title = styled.div`
  background-color: #ffcc00;
  width: 100%;
  text-align: center;
  font-weight: bold;
  padding: 20px 0;
  margin-bottom: 20px;
  font-size: 24px;

  @media (max-width: 768px) {
    padding: 10px 0;
    font-size: 18px;
  }
`;

const Subtitle = styled.div`
  color: #666;
  font-size: 20px;
  margin-bottom: 25px;

  @media (max-width: 768px) {
    font-size: 16px;
    margin-bottom: 15px;
  }
`;

const Description = styled.div`
  font-size: 18px;
  color: #333;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const PermacultureCard = ({ imageUrl, name, description, fonction }) => {
  return (
    <Card>
      <ImageContainer>
        <DiamondBackground />
        <Image src={imageUrl} alt={name} />
      </ImageContainer>
      <Title>{name} Permaculture</Title>
      <Subtitle>{fonction}</Subtitle>
      <Description>
        Au côté de <strong><u>{description}</u></strong>, notre soeur Souad s'occupe de construire la
        stratégie et l'animation de ce centre d'interaction du côté de FEA1 soeurs. Ils sont aussi épaulés
        par Abdelliah, car au vu des enjeux pour construire la société post-COVID-19, la permaculture propose
        des solutions concrètes.
      </Description>
    </Card>
  );
};

export default PermacultureCard;
