export const FACTURE_CREATE_REQUEST = 'FACTURE_CREATE_REQUEST'
export const FACTURE_CREATE_SUCCESS = 'FACTURE_CREATE_SUCCESS'
export const FACTURE_CREATE_FAIL = 'FACTURE_CREATE_FAIL'
export const FACTURE_CREATE_RESET = 'FACTURE_CREATE_RESET'


export const FACTURE_DELETE_REQUEST = 'FACTURE_DELETE_REQUEST'
export const FACTURE_DELETE_SUCCESS = 'FACTURE_DELETE_SUCCESS'
export const FACTURE_DELETE_FAIL = 'FACTURE_DELETE_FAIL'



export const FACTURE_LIST_REQUEST = 'FACTURE_LIST_REQUEST'
export const FACTURE_LIST_SUCCESS = 'FACTURE_LIST_SUCCESS'
export const FACTURE_LIST_FAIL = 'FACTURE_LIST_FAIL'



export const FACTURE_DETAILS_REQUEST = 'FACTURE_DETAILS_REQUEST'
export const FACTURE_DETAILS_SUCCESS = 'FACTURE_DETAILS_SUCCESS'
export const FACTURE_DETAILS_FAIL = 'FACTURE_DETAILS_FAIL'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'


export const FACTURE_UPDATE_REQUEST = 'FACTURE_UPDATE_REQUEST'
export const FACTURE_UPDATE_SUCCESS = 'FACTURE_UPDATE_SUCCESS'
export const FACTURE_UPDATE_FAIL = 'FACTURE_UPDATE_FAIL'
export const FACTURE_UPDATE_RESET = 'FACTURE_UPDATE_RESET'

export const FACTURE_LASTTOTAL_REQUEST = 'FACTURE_LASTTOTAL_REQUEST'
export const FACTURE_LASTTOTAL_SUCCESS = 'FACTURE_LASTTOTAL_SUCCESS'
export const FACTURE_LASTTOTAL_FAIL = 'FACTURE_LASTTOTAL_FAIL'
export const FACTURE_LASTTOTAL_RESET= 'FACTURE_LASTTOTAL_RESET'


export const ENVOYER_MAIL_SUCCESS = 'ENVOYER_MAIL_SUCCESS'
export const ENVOYER_MAIL_FAILURE = 'ENVOYER_MAIL_FAILURE'
export const ENVOYER_MAIL_REQUEST = 'ENVOYER_MAIL_REQUEST'

export const ENVOYER_MAIL2_SUCCESS = 'ENVOYER_MAIL2_SUCCESS'
export const ENVOYER_MAIL2_FAILURE = 'ENVOYER_MAIL2_FAILURE'
export const ENVOYER_MAIL2_REQUEST = 'ENVOYER_MAIL2_REQUEST'

export const ENVOYER_MAIL3_SUCCESS = 'ENVOYER_MAIL3_SUCCESS'
export const ENVOYER_MAIL3_FAILURE = 'ENVOYER_MAIL3_FAILURE'
export const ENVOYER_MAIL3_REQUEST = 'ENVOYER_MAIL3_REQUEST'


export const ENVOYER_MAIL4_SUCCESS = 'ENVOYER_MAIL4_SUCCESS'
export const ENVOYER_MAIL4_FAILURE = 'ENVOYER_MAIL4_FAILURE'
export const ENVOYER_MAIL4_REQUEST = 'ENVOYER_MAIL4_REQUEST'


export const ENVOYER_MAIL5_SUCCESS = 'ENVOYER_MAIL5_SUCCESS'
export const ENVOYER_MAIL5_FAILURE = 'ENVOYER_MAIL5_FAILURE'
export const ENVOYER_MAIL5_REQUEST = 'ENVOYER_MAIL5_REQUEST'