const ACTIONS = {
    LOGIN: 'LOGIN',
    GET_TOKEN: 'GET_TOKEN',
    GET_USER: 'GET_USER',
    GET_ALL_USERS: 'GET_ALL_USERS',
    GET_ALL_SOFT_SKILLS: 'GET_ALL_SOFT_SKILLS',
    GET_ALL_HARD_SKILLS: 'GET_ALL_HARD_SKILLS',
    GET_ALL_CANDIDAT: 'GET_ALL_CANDIDAT',
    GET_ALL_PROCESS: 'GET_ALL_PROCESS',
    GET_ALL_CLIENT: 'GET_ALL_CLIENT',
    GET_PROCESS: 'GET_PROCESS',
    GET_RECRUTEMENT: 'GET_RECRUTEMENT',
    GET_ALL_CLIENTF: 'GET_ALL_CLIENTF',
    GET_ALL_PRODUCT: 'GET_ALL_PRODUCT',
    GET_ALL_FACTURE: 'GET_ALL_FACTURE',
    GET_ALL_EVENT: 'GET_ALL_EVENT',
    GET_ALL_DEVIS: 'GET_ALL_DEVIS',
    GET_ALL_FACADMIN :'GET_ALL_FACADMIN',
    GET_ALL_DEVADMIN :'GET_ALL_DEVADMIN',
    GET_ALL_PRODADMIN :'GET_ALL_PRODADMIN',
    GET_ALL_CLIENTADMIN :'GET_ALL_CLIENTADMIN',
    UPDATE_USER :'UPDATE_USER',




}

export default ACTIONS