import React from 'react'
import AtefImage from '../../image/Seminaires.png'; // Importez l'image en utilisant require


const SeminaireIcon = () => {
  return (
    <div>
   <img src={AtefImage}  alt="" />
    </div>
  )
}

export default SeminaireIcon