import React from 'react';

const Contact = () => {
  return (
    <div style={{ marginBottom: '800px' }}>
      <h1>teamorga.fea@gmail.com</h1> 
      
    </div>
  );
};

export default Contact;
