import React from 'react';

const FeedBack = () => {
  return (
    <div>
      <p>
        Afin de construire un réseau de qualité le feedback est très important et encourage <br/>
        toute la communauté FEA à faire de même . Le feedback c'est une rencontre bénéfique<br/>
        via FEA , une opportunité développée , un coup de main sur une demande Pro <br/>
        importante , un merci que vous souhaitez communiquer , etc . Dans cette section vous<br/>
        pourrez retrouver tous les feedbacks des membres sur l'Entraide Pro. Aussi bien ce <br/>
        qui a marché que ce qu'il reste à améliorer .
      </p>
      
    </div>
  );
};

export default FeedBack;
