import React from 'react';
import { useNavigate } from 'react-router-dom';

const PlanComponent = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/inv/FicheProjet'); // Replace with your route path
  };

  // Inline styles
  const styles = {
    container: {
      padding: '20px',
      backgroundColor: '#f5f5f5',
      borderRadius: '8px',
      //marginBottom: 'px', // Spacing below the component
      maxWidth: '800px', // Limit max width for larger screens
      margin: '0 auto', // Center horizontally
      position: 'relative', // Positioning relative to the normal flow
      top: '0', // Align with the top
      // Ensure no extra space is created
      boxSizing: 'border-box', // Include padding in width calculations
    },
    heading: {
      fontSize: '24px',
      marginBottom: '10px',
    },
    paragraph: {
      fontSize: '16px',
      lineHeight: '1.5', // Improve readability
    },
    button: {
      backgroundColor: '#00aaff',
      color: '#fff',
      border: 'none',
      padding: '10px 20px',
      borderRadius: '5px',
      cursor: 'pointer',
      fontSize: '16px',
    },
    // Responsive styles
    '@media (max-width: 600px)': {
      container: {
        padding: '15px', // Reduce padding for smaller screens
        marginBottom: '30px', // Smaller margin for mobile
      },
      heading: {
        fontSize: '20px', // Smaller heading for mobile
      },
      paragraph: {
        fontSize: '14px', // Smaller text for mobile
      },
      button: {
        padding: '8px 16px', // Smaller button for mobile
        fontSize: '14px', // Smaller font for button
      },
    },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Notre Plan</h2>
      <p style={styles.paragraph}>
        En se focalisant en premier lieu sur les francophones, dans la continuité d’initiatives telles que les 
        « Mokhtar Awards » ou « OT1 », l’objectif principal est d’inciter à une consommation alternative des 
        médias et productions audiovisuelles de qualité.
      </p>
      <button 
        onClick={handleClick} 
        style={styles.button}
      >
        Fiche Projet
      </button>
    </div>
  );
};

export default PlanComponent;
