export const showErrMsg = (msg) => {
    return false
}

export const showSuccessMsg = (msg) => {
    //const notify = () => toast("Wow so easy !");

    return true
    /*return <ToastContainer
    position="bottom-left"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    />*/
}