import React from 'react'
import AtefImage from '../../image/Organigramme2.png'; // Importez l'image en utilisant require

const LinkToOrganigramme = () => {
  return (
    <div>
         <img src={AtefImage}  alt="" />
   
    </div>
  )
}

export default LinkToOrganigramme