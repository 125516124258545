import React from 'react'
import AtefImage from '../../image/EFAliv.png'; // Importez l'image en utilisant require

const EFAliv = () => {
  return (
    <div>
           <img src={AtefImage}  alt="" />
    </div>
  )
}

export default EFAliv