import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

function AppBarComponent2({ handleDrawerOpen, pageTitle }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <AppBar position="static">
      <Toolbar>
        {/* Bouton de menu à gauche sur tous les écrans */}
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerOpen}
        >
          <MenuIcon />
        </IconButton>

        {/* Box qui prend tout l'espace pour centrer le titre */}
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
          <Typography variant={isMobile ? "h6" : "h5"} component="div">
            {pageTitle}
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default AppBarComponent2;
