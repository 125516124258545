import React from 'react'
import AtefImage from '../../../image/icon27.png'; // Importez l'image en utilisant require

const Icon27 = () => {
  return (
    <div>
            <img src={AtefImage}  alt="" />

    </div>
  )
}

export default Icon27