import React, { useState, useEffect } from 'react';
import NavbarLateralApprendre from './NavbarLateralApprendre';
import ApprendreCard from './ApprendreCard';
import PlanComponent from './planComponent';
import { useSelector } from 'react-redux';
import { ShowApprendresApi } from "../../redux/actions/apprendre/apprendreActions";
import { connect } from 'react-redux';
import Contact from './Contact';
import Actions from './Actions';
import MenuIcon from '@mui/icons-material/Menu';

const AppNavbar = ({ ShowApprendresApi }) => {
  const token = useSelector(state => state.token);
  const apprendres = useSelector(state => state.apprendres);

  const [selectedContent, setSelectedContent] = useState(null);
  const [showFooter, setShowFooter] = useState(false); 
  const [showHeader, setShowHeader] = useState(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState(true); // Navbar ouverte par défaut

  const activeApprendres = apprendres.filter(apprendre => apprendre.isActive);
  const firstActiveApprendre = activeApprendres[0] || {};

  useEffect(() => {
    ShowApprendresApi(token);
  }, [token]);

  const contentData = {
    'Équipe': (
      <ApprendreCard
        imageUrl={firstActiveApprendre.imageUrl}
        description={firstActiveApprendre.selectedClients?.[0]?.firstName}
        fonction={firstActiveApprendre.fonction}
      />
    ),
    'Notre Plan': <PlanComponent />,
    'Nos Actions': <Actions description={firstActiveApprendre.selectedClients?.[0]?.firstName} />,
    'Contact': <Contact />,
  };

  const handleMenuClick = (itemName) => {
    setSelectedContent(contentData[itemName] || null);

    if (itemName === 'Équipe') {
      setShowFooter(true);
      setShowHeader(true); 
    } else {
      setShowFooter(false);
      setShowHeader(false); 
    }
    setIsNavbarOpen(false); // Fermer la navbar après sélection
  };

  return (
    <div style={styles.container}>
      {showHeader && firstActiveApprendre.title && firstActiveApprendre.selectedClients?.[0]?.firstName && (
        <div style={styles.header}>
          {firstActiveApprendre.title} {firstActiveApprendre.selectedClients[0].firstName}
        </div>
      )}

      <div style={styles.main}>
        <div onClick={() => setIsNavbarOpen(!isNavbarOpen)} style={styles.menuIcon}>
          <MenuIcon />
        </div>

        {isNavbarOpen && <NavbarLateralApprendre onMenuItemClick={handleMenuClick} />}

        <div style={styles.content}>
          {selectedContent}
        </div>
      </div>

      {showFooter && firstActiveApprendre.title && firstActiveApprendre.selectedClients?.[0]?.firstName && (
        <div style={styles.footer}>
          {firstActiveApprendre.title} {firstActiveApprendre.selectedClients[0].firstName}
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  header: {
    textAlign: 'center',
    padding: '1px',
    backgroundColor: '#f1f1f1',
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    marginTop: '20px',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
  },
  footer: {
    position: 'fixed',
    bottom: '60px',
    width: '100%',
    textAlign: 'center',
    padding: '10px',
    backgroundColor: '#f1f1f1',
    zIndex: 1000,
  },
  menuIcon: {
    cursor: 'pointer',
    padding: '10px',
    backgroundColor: '#eee',
    borderRadius: '5px',
  },
  '@media (max-width: 768px)': {
    main: {
      flexDirection: 'column',
    },
    content: {
      marginTop: '10px',
      padding: '5px',
    },
    footer: {
      display: 'none',
    },
  },
};

export default connect(null, { ShowApprendresApi })(AppNavbar);
