export const EVENT_CREATE_REQUEST = 'EVENT_CREATE_REQUEST'
export const EVENT_CREATE_SUCCESS = 'EVENT_CREATE_SUCCESS'
export const EVENT_CREATE_FAIL = 'EVENT_CREATE_FAIL'
export const EVENT_CREATE_RESET = 'EVENT_CREATE_RESET'


export const EVENT_DELETE_REQUEST = 'EVENT_DELETE_REQUEST'
export const EVENT_DELETE_SUCCESS = 'EVENT_DELETE_SUCCESS'
export const EVENT_DELETE_FAIL = 'EVENT_DELETE_FAIL'



export const EVENT_LIST_REQUEST = 'EVENT_LIST_REQUEST'
export const EVENT_LIST_SUCCESS = 'EVENT_LIST_SUCCESS'
export const EVENT_LIST_FAIL = 'EVENT_LIST_FAIL'



export const EVENT_DETAILS_REQUEST = 'EVENT_DETAILS_REQUEST'
export const EVENT_DETAILS_SUCCESS = 'EVENT_DETAILS_SUCCESS'
export const EVENT_DETAILS_FAIL = 'EVENT_DETAILS_FAIL'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'


export const EVENT_UPDATE_REQUEST = 'EVENT_UPDATE_REQUEST'
export const EVENT_UPDATE_SUCCESS = 'EVENT_UPDATE_SUCCESS'
export const EVENT_UPDATE_FAIL = 'EVENT_UPDATE_FAIL'
export const EVENT_UPDATE_RESET = 'EVENT_UPDATE_RESET'


export const ENVOYER_MAIL_SUCCESS = 'ENVOYER_MAIL_SUCCESS'
export const ENVOYER_MAIL_FAILURE = 'ENVOYER_MAIL_FAILURE'
export const ENVOYER_MAIL_REQUEST = 'ENVOYER_MAIL_REQUEST'

export const ENVOYER_MAIL2_SUCCESS = 'ENVOYER_MAIL2_SUCCESS'
export const ENVOYER_MAIL2_FAILURE = 'ENVOYER_MAIL2_FAILURE'
export const ENVOYER_MAIL2_REQUEST = 'ENVOYER_MAIL2_REQUEST'

export const ENVOYER_MAIL3_SUCCESS = 'ENVOYER_MAIL3_SUCCESS'
export const ENVOYER_MAIL3_FAILURE = 'ENVOYER_MAIL3_FAILURE'
export const ENVOYER_MAIL3_REQUEST = 'ENVOYER_MAIL3_REQUEST'


export const ENVOYER_MAIL4_SUCCESS = 'ENVOYER_MAIL4_SUCCESS'
export const ENVOYER_MAIL4_FAILURE = 'ENVOYER_MAIL4_FAILURE'
export const ENVOYER_MAIL4_REQUEST = 'ENVOYER_MAIL4_REQUEST'


export const ENVOYER_MAIL5_SUCCESS = 'ENVOYER_MAIL5_SUCCESS'
export const ENVOYER_MAIL5_FAILURE = 'ENVOYER_MAIL5_FAILURE'
export const ENVOYER_MAIL5_REQUEST = 'ENVOYER_MAIL5_REQUEST'