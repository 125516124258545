import React from 'react';

const Recommandation = () => {
  return (
    <div style={{ marginBottom: '800px' }}>
      <p>
  Pour retrouver les replays de tous nos grands entretiens , rendez-vous dans rubrique Replay .
      </p>
      
    </div>
  );
};

export default Recommandation;