import React from 'react'
import AtefImage from '../../image/EFARes.png'; // Importez l'image en utilisant require

const EFARes = () => {
  return (
    <div>
     <img src={AtefImage}  alt="" />
      </div>
  )
}

export default EFARes