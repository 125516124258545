import React from 'react'
import AtefImage from '../../../image/icon7.png'; // Importez l'image en utilisant require

const Icon7 = () => {
  return (
    <div>
      <img src={AtefImage}  alt="" />

    </div>
  )
}

export default Icon7