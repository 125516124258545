import React from 'react'
import AtefImage from '../../../image/icon16.png'; // Importez l'image en utilisant require

const Icon16 = () => {
  return (
    <div>
     <img src={AtefImage}  alt="" />
    </div>
  )
}

export default Icon16