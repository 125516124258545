import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { ShowEntraidesApi } from "../../redux/actions/entraide/entraideActions";

const FicheProjet = ({ ShowEntraidesApi }) => {
  const token = useSelector(state => state.token);
  const entraides = useSelector(state => state.entraides);
  const [pdfContent, setPdfContent] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const activeEntraides = entraides.filter(entraide => entraide.isActive);
  const firstActiveEntraide = activeEntraides[0] || {};

  useEffect(() => {
    ShowEntraidesApi(token);
  }, [token]);

  useEffect(() => {
    if (firstActiveEntraide && firstActiveEntraide.pdfUrl2) {
      const fetchPDF = async () => {
        try {
          const response = await fetch(firstActiveEntraide.pdfUrl2);
          const blob = await response.blob();
          setPdfContent(URL.createObjectURL(blob));
        } catch (error) {
          console.error('Error loading PDF:', error);
        }
      };
      fetchPDF();
    }
  }, [firstActiveEntraide]);

  // تحديث حالة isMobile عند تغيير حجم الشاشة
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={styles.container}>
      {pdfContent && (
        <iframe
          title="PDF Viewer"
          src={`${pdfContent}`}
          style={isMobile ? styles.iframeMobile : styles.iframe}
        />
      )}
    </div>
  );
};

const styles = {
  container: {
    width: '100vw',
    height: '100vh',
    margin: 0,
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  iframe: {
    width: '90vw',
    height: '90vh',
    border: 'none',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  iframeMobile: {
    width: '100vw',
    height: '100vh',
    border: 'none',
  },
};

export default connect(null, { ShowEntraidesApi })(FicheProjet);
