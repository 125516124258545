import React from 'react'
import AtefImage from '../../image/equipe.png'; // Importez l'image en utilisant require

const EquipeIcon = () => {
  return (
    <div>
    <img src={AtefImage}  alt="" />
    </div>
  )
}

export default EquipeIcon