export const INVESTIR_CREATE_REQUEST = 'INVESTIR_CREATE_REQUEST'
export const INVESTIR_CREATE_SUCCESS = 'INVESTIR_CREATE_SUCCESS'
export const INVESTIR_CREATE_FAIL = 'INVESTIR_CREATE_FAIL'
export const INVESTIR_CREATE_RESET = 'INVESTIR_CREATE_RESET'


export const INVESTIR_DELETE_REQUEST = 'INVESTIR_DELETE_REQUEST'
export const INVESTIR_DELETE_SUCCESS = 'INVESTIR_DELETE_SUCCESS'
export const INVESTIR_DELETE_FAIL = 'INVESTIR_DELETE_FAIL'



export const INVESTIR_LIST_REQUEST = 'INVESTIR_LIST_REQUEST'
export const INVESTIR_LIST_SUCCESS = 'INVESTIR_LIST_SUCCESS'
export const INVESTIR_LIST_FAIL = 'INVESTIR_LIST_FAIL'



export const INVESTIR_DETAILS_REQUEST = 'INVESTIR_DETAILS_REQUEST'
export const INVESTIR_DETAILS_SUCCESS = 'INVESTIR_DETAILS_SUCCESS'
export const INVESTIR_DETAILS_FAIL = 'INVESTIR_DETAILS_FAIL'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'


export const INVESTIR_UPDATE_REQUEST = 'INVESTIR_UPDATE_REQUEST'
export const INVESTIR_UPDATE_SUCCESS = 'INVESTIR_UPDATE_SUCCESS'
export const INVESTIR_UPDATE_FAIL = 'INVESTIR_UPDATE_FAIL'
export const INVESTIR_UPDATE_RESET = 'INVESTIR_UPDATE_RESET'


export const ENVOYER_MAIL_SUCCESS = 'ENVOYER_MAIL_SUCCESS'
export const ENVOYER_MAIL_FAILURE = 'ENVOYER_MAIL_FAILURE'
export const ENVOYER_MAIL_REQUEST = 'ENVOYER_MAIL_REQUEST'

export const ENVOYER_MAIL2_SUCCESS = 'ENVOYER_MAIL2_SUCCESS'
export const ENVOYER_MAIL2_FAILURE = 'ENVOYER_MAIL2_FAILURE'
export const ENVOYER_MAIL2_REQUEST = 'ENVOYER_MAIL2_REQUEST'

export const ENVOYER_MAIL3_SUCCESS = 'ENVOYER_MAIL3_SUCCESS'
export const ENVOYER_MAIL3_FAILURE = 'ENVOYER_MAIL3_FAILURE'
export const ENVOYER_MAIL3_REQUEST = 'ENVOYER_MAIL3_REQUEST'


export const ENVOYER_MAIL4_SUCCESS = 'ENVOYER_MAIL4_SUCCESS'
export const ENVOYER_MAIL4_FAILURE = 'ENVOYER_MAIL4_FAILURE'
export const ENVOYER_MAIL4_REQUEST = 'ENVOYER_MAIL4_REQUEST'


export const ENVOYER_MAIL5_SUCCESS = 'ENVOYER_MAIL5_SUCCESS'
export const ENVOYER_MAIL5_FAILURE = 'ENVOYER_MAIL5_FAILURE'
export const ENVOYER_MAIL5_REQUEST = 'ENVOYER_MAIL5_REQUEST'

export const INVESTIR_UPDATE_STATUS_REQUEST = 'INVESTIR_UPDATE_STATUS_REQUEST';
export const INVESTIR_UPDATE_STATUS_SUCCESS = 'INVESTIR_UPDATE_STATUS_SUCCESS';
export const INVESTIR_UPDATE_STATUS_FAIL = 'INVESTIR_UPDATE_STATUS_FAIL';

// actionTypes.js
export const UPDATE_INVESTIR_STATUS_REQUEST = "UPDATE_INVESTIR_STATUS_REQUEST";
export const UPDATE_INVESTIR_STATUS_SUCCESS = "UPDATE_INVESTIR_STATUS_SUCCESS";
export const UPDATE_INVESTIR_STATUS_FAILURE = "UPDATE_INVESTIR_STATUS_FAILURE";