import React from 'react'
import AtefImage from '../../image/Livretblanc.PNG'; // Importez l'image en utilisant require

const LivretBlanc = () => {
  return (
    <div>
    <img src={AtefImage}  alt=""/>
    </div>
  )
}

export default LivretBlanc