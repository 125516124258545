import React from 'react'
import AtefImage from '../../image/equipee.png'; // Importez l'image en utilisant require

const Equipe2 = () => {
  return (
    <div>
    <img src={AtefImage}  alt="" />
    </div>
  )
}

export default Equipe2