import React from 'react'
import AtefImage from '../../image/Podcast.png'; // Importez l'image en utilisant require

const PodcastIcon = () => {
  return (
    <div>
   <img src={AtefImage}  alt="" />

    </div>
  )
}

export default PodcastIcon