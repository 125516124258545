import React from 'react';

const Focus = () => {
  return (
    <div style={{ marginBottom: '800px' }}>
      <h1>
     Nous venons à fin mai d'atteindre l'objectif fixé à 5000 <br/>
     euros pour la collecte de notre frère Omar pour l'école Al-Fitra<br/>
     dans le Nord de la France. Vous souhaitez lancer <br/>
     le prochain Sprint Sadaqa? RDV sur le rubrique "Quezaco Sprint Sadaqa"<br/>
      </h1>
    </div>
  );
};

export default Focus;