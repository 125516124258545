import React from 'react'
import AtefImage from '../../image/Challenge.png'; // Importez l'image en utilisant require

const ChallengeIcon = () => {
  return (
    <div>
           <img src={AtefImage}  alt="" />

    </div>
  )
}

export default ChallengeIcon