import React, { useState, useEffect } from 'react';
import NavbarLateralSadaqa from './NavbarLateralSadaqa';
import SadaqaCard from './sadaqaCard';
import PlanComponent from './planComponent';
import { useSelector } from 'react-redux';
import { ShowSadaqasApi } from "../../redux/actions/sadaqa/sadaqaActions";
import { connect } from 'react-redux';
import Contact from './Contact';
import Quezaco from './Quezaco';
import Focus from './Focus';
import Regle from './Regle';
import Certificat from './Certificat';
import Actions from './Actions';
import MenuIcon from '@mui/icons-material/Menu';

const AppNavbar = ({ ShowSadaqasApi }) => {
  const token = useSelector(state => state.token);
  const sadaqas = useSelector(state => state.sadaqas);

  const [selectedContent, setSelectedContent] = useState(null);
  const [showFooter, setShowFooter] = useState(false); 
  const [showHeader, setShowHeader] = useState(false); 
  const [isNavbarOpen, setIsNavbarOpen] = useState(true); 
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); 

  const activeSadaqas = sadaqas.filter(sadaqa => sadaqa.isActive);
  const firstActiveSadaqa = activeSadaqas[0] || {};

  useEffect(() => {
    ShowSadaqasApi(token);

    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [token]);

  const contentData = {
    'Équipe': (
      <SadaqaCard
        imageUrl={firstActiveSadaqa.imageUrl}
        description={firstActiveSadaqa.selectedClients?.[0]?.firstName}
        fonction={firstActiveSadaqa.fonction}
      />
    ),
    'Notre Plan': <PlanComponent />,
    'Quezaco Sprint Sadaqa': <Quezaco />,
    'Focus Sadaqa du moment': <Focus />,
    'Certificats de remerciement': <Certificat />,
    'Régles des Sprints-Sadaqa': <Regle />,
    'Nos actions': <Actions />,
    'Contact': <Contact />,
  };

  const handleMenuClick = (itemName) => {
    setSelectedContent(contentData[itemName] || null);

    if (itemName === 'Équipe') {
      setShowFooter(true);
      setShowHeader(true); 
    } else {
      setShowFooter(false);
      setShowHeader(false); 
    }
    setIsNavbarOpen(false); 
  };

  return (
    <div style={styles.container}>
      {showHeader && firstActiveSadaqa.title && firstActiveSadaqa.selectedClients?.[0]?.firstName && (
        <div style={styles.header}>
          {firstActiveSadaqa.title} {firstActiveSadaqa.selectedClients[0].firstName}
        </div>
      )}

      <div style={styles.main}>
        <div onClick={() => setIsNavbarOpen(!isNavbarOpen)} style={styles.menuIcon}>
          <MenuIcon />
        </div>

        {isNavbarOpen && <NavbarLateralSadaqa onMenuItemClick={handleMenuClick} />}

        <div style={styles.content}>
          {selectedContent}
        </div>
      </div>

      {showFooter && !isMobile && firstActiveSadaqa.title && firstActiveSadaqa.selectedClients?.[0]?.firstName && (
        <div style={styles.footer}>
          {firstActiveSadaqa.title} {firstActiveSadaqa.selectedClients[0].firstName}
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  header: {
    textAlign: 'center',
    padding: '1px',
    backgroundColor: '#f1f1f1',
  },
  main: {
    flexGrow: 1,
    display: 'flex',
    marginTop: '20px',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px',
  },
  footer: {
    position: 'fixed',
    bottom: '60px',
    width: '100%',
    textAlign: 'center',
    padding: '10px',
    backgroundColor: '#f1f1f1',
    zIndex: 1000,
  },
  menuIcon: {
    cursor: 'pointer',
    padding: '10px',
    backgroundColor: '#eee',
    borderRadius: '5px',
  },
  '@media (max-width: 768px)': {
    main: {
      flexDirection: 'column',
    },
    content: {
      marginTop: '10px',
      padding: '5px',
    },
    footer: {
      display: 'none',
    },
  },
};

export default connect(null, { ShowSadaqasApi })(AppNavbar);
